<template>
  <div class="col-12 p-0" v-if="total_filters > 0">
    <div>
        <b-row ref="collapsable_filters" class="collapsable-filters">
          <div v-for="(item, index) in first_filters" :key="index" class="d-flex flex-wrap mx-1 mb-2">
            <span v-if="item.multiple_values && validModel(item.syntax, item.value)" class="text-filter-under">{{item.text}}: {{getText(item.syntax, item.value)}}
              <feather-icon icon="XIcon" class="cursor-pointer" @click="updateModel(item, item.index)"></feather-icon>
            </span> 
            <span v-else class="text-filter-under">{{item.text}} : {{item.value}} 
              <feather-icon icon="XIcon" class="cursor-pointer" @click="deleteFilter(item.filter)"></feather-icon>
            </span>
          </div>
        </b-row>

        <div class="chevron-icon mb-1" v-if="more_filters">
          <b-tooltip target="view_filters" variant="primary" :title="!visible ? $t('search.view_filters') : $t('search.hide_filters')"></b-tooltip>
          <div id="view_filters" @click="collapseFilters()">
            <b-icon class="icon-view-filters" :icon="!visible ? 'chevron-down' : 'chevron-up'" font-scale="1.5"/>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BIcon, BTooltip
} from 'bootstrap-vue'

export default {
  name: 'ApplyFilters',
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  components: {
    BIcon,
    BRow,
    BTooltip
  },
  data () {
    return {
      visible:false,
      total_filters: 0,
      first_filters: [],
      more_filters: false
    }
  },
  created () {
  },
  methods: {
    collapseFilters () {
      this.visible = !this.visible
      this.$refs.collapsable_filters.classList.toggle('collapsable-filters')
    },
    updateModel(filter, index) {
      if (filter.other_values.length > 1) {
        if (filter.filter === 'audience_locations') {
          const deleted = filter.other_values[index]
          this.$emit('updateModel', {
            'name_emit': 'removeAudienceLocation',
            'params': [
              deleted,
              index,
              index > 0,
              true
            ]
          })
        } else if (filter.filter === 'brand_safety') {
          filter.other_values.splice(index, 1)
          this.$emit('updateModel', {
            'name_emit': 'changeBrandSafety',
            'params': [filter.other_values]
          })
        } else if (filter.filter === 'influencer_category') {
          const id_category_to_deleted = filter.other_values[index].id
          filter.other_values.splice(index, 1);
          this.$emit('updateModel', {
            'name_emit': 'changeInfluencerCategory',
            'params': [
              filter.other_values,
              id_category_to_deleted
            ],
          });
        }
      } else {
        this.deleteFilter(filter.filter)
      }
    },
    validModel(syntax, model) {
      let valid = true
      syntax.forEach(s => {
        if (s.type === 'var') {
          valid = model[s.name] !== undefined
        }
      })
      return valid
    },
    getText(syntax, model) {
      let text = ''
      syntax.forEach(s => {
        if (s.type === 'var') {
          text += model[s.name]
        } else if (s.type === 'str_translate') {
          text += this.$t(`${s.name}.${model}`)
        } else {
          text += s.name
        }
      })
      return text
    },
    deleteFilter(filter_name) {
      this.$emit('deleteFilter', filter_name)
    },
    countFilters(new_filters) {
      const all_filters = []
      new_filters.forEach(filter => {
        if (filter.multiple_values) {
          filter.models[0].value.forEach((fil, index) => {
            const filter_obj = {
              'filter': filter.filter,
              'syntax': filter.syntax,
              'value': fil,
              'text': filter.text,
              'other_values': filter.models[0].value,
              'multiple_values': true,
              index
            }
            all_filters.push(filter_obj)
          })
          this.total_filters += filter.models[0].value.length
        } else {
          this.total_filters += 1
          const filter_obj = {
            'filter': filter.filter,
            'syntax': '',
            'value': filter.value,
            'text': filter.text,
            'other_values': [],
            'multiple_values': false,
            'index': 0
          }
          all_filters.push(filter_obj)
        }
      })

      this.first_filters = all_filters

      setTimeout(() => {
        if (this.total_filters > 0) this.more_filters = this.$refs.collapsable_filters.clientWidth < this.$refs.collapsable_filters.scrollWidth || 42 < this.$refs.collapsable_filters.scrollHeight;
        else this.more_filters = false
      }, 50)
    }
  },
  watch: {
    filters(new_filters) {
      this.total_filters = 0;
      this.countFilters(new_filters)
    }
  }
}
</script>
<style>
.chevron-icon {
  text-align: center;
  cursor: pointer;
}
.icon-view-filters {
  color: #82868b !important;
}
.collapsable-filters {
  height: 42px;
  overflow: hidden;
}
</style>